import { createContext, useState } from "react";
import axios from "axios";

export const UserContext = createContext({
  login: () => { },
  logout: () => { },
  user: null,
  isAuthenticated: false,
  error: null
});

// eslint-disable-next-line
export default ({ children }) => {
  const userSaved = window.localStorage.getItem("user");
  const [authData, setAuthData] = useState({
    error: null,
    isAuthenticated: !!window.localStorage.getItem("accessToken"),
    isLoading: false,
    user: JSON.parse(userSaved) ?? null,
    tokens: null
  });

  const login = async (credentials) => {
    try {
      await setAuthData({ ...authData, isLoading: true, error: null });
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/login`,
        credentials
      );
      const { user, accessToken } = res.data;
      switch (res.status) {
        case 200: {
          await setAuthData({
            ...authData,
            user: user,
            isAuthenticated: true
          });

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("accessToken", accessToken);
          break;
        }
        case 401:
          await setAuthData({ ...authData, error: res.data, isLoading: false });
          // on laisse pas l'erreur plus de 4 secondes
          setTimeout(() => {
            !user.error && setAuthData({ ...authData, error: null });
          }, 4000);
          break;
        default:
          setAuthData({
            ...authData,
            error: "Le back à rendu une réponse non attendu"
          });
      }
    } catch (error) {
      setAuthData({ ...authData, error });
    }
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    setAuthData({ ...authData, isAuthenticated: false });
  };

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        authData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
