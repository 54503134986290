import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalOrExternalPlusFn} from "../../../lib/roleUtils";

export const DropdownItemMove = ({item, role, canWrite, onMove}) => {
  return isAdminOrInternalOrExternalPlusFn(role) && canWrite ? <DropdownItem
    title="Déplacer"
    onClick={() => {
      onMove(item);
    }}
  /> : <></>;
}