import axios from "axios";

export const getSettings = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/settings`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};

export const addNotificationEmailAddress = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/settings/notification-emails`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};

export const removeNotificationEmailAddress = (values) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/v1/settings/notification-emails`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};

export const addDefaultFolder = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/settings/default-folders`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};

export const removeDefaultFolder = (values) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/v1/settings/default-folders`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};
