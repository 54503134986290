import React from "react";
import { useMutation } from "react-query";
import { removeFolder } from "../api/folder.api";
import AppModal from "../components/AppModal";
import Button from "../components/Button";

const RemoveRootFolderModal = ({ isOpen, onClose, folder, onSuccess }) => {
  const removeFileMutation = useMutation(removeFolder, {
    onSuccess,
  });

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Suppression du dossier</h3>
        {isOpen && (
          <div>
            <div className="mt-2">
              Êtes-vous sûr de vouloir supprimer le dossier{" "}
              <span className="font-medium">{folder.name}</span> ?
            </div>

            <div className="mt-4 text-right">
              <Button
                color="red"
                onClick={() => removeFileMutation.mutate(folder._id)}
                isDisabled={removeFileMutation.isLoading}
              >
                {removeFileMutation.isLoading
                  ? "Suppression en cours..."
                  : "Supprimer le dossier"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default RemoveRootFolderModal;
