import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  getSettings,
  removeNotificationEmailAddress,
} from "../api/settings.api";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Loader from "../components/Loader";
import AddEmailNotificationModal from "../modals/AddEmailNotificationModal";
import { UserContext } from "../contexts/UserContext";
import RootFoldersList from "../components/RootFoldersList";
import DefaultFolders from "../components/DefaultFolders";

const Settings = () => {
  const { authData } = React.useContext(UserContext);
  const getSettingsQuery = useQuery("settings", getSettings);

  const removeNotificationEmailAddressMutation = useMutation(
    removeNotificationEmailAddress,
    {
      onSuccess: () => getSettingsQuery.refetch(),
    }
  );

  const [
    addEmailNotificationModal,
    setAddEmailNotificationModal,
  ] = React.useState(false);
  return (
    <div className="w-full">
      {getSettingsQuery.isLoading ? (
        <Loader />
      ) : (
          <div className="grid grid-cols-2 gap-6">
            <div>
              <PageTitle>Notification</PageTitle>

              {authData.user.role === "admin" && (
                <div className="mt-2">
                  <Button onClick={() => setAddEmailNotificationModal(true)}>
                    Ajouter un email de notification
                  </Button>
                </div>
              )}


              <div className="mt-3">
                {getSettingsQuery.data.data.notificationEmailAddresses &&
                  getSettingsQuery.data.data.notificationEmailAddresses.length ? (
                    <div>
                      {getSettingsQuery.data.data.notificationEmailAddresses.map(
                        (emailAddress) => {
                          return (
                            <div
                              className="flex justify-between flex-grow px-2 py-3 border-b-2"
                              key={emailAddress}
                            >
                              <div className="flex-grow pr-2 font-medium">{emailAddress}</div>
                              {authData.user.role === "admin" && (
                                <div
                                  onClick={() =>
                                    removeNotificationEmailAddressMutation.mutate({
                                      email: emailAddress,
                                    })
                                  }
                                >
                                  <span className="flex text-sm text-red-400 cursor-pointer hover:underline hover:text-red-500">
                                    Supprimer l'email
                            </span>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <div className="text-gray-500">
                        Vous n'avez pas configurer d'email de notification.
                </div>
                    </div>
                  )}

              </div>

              <div className="mt-6">
                <DefaultFolders />
              </div>

            </div>

            <div>
              <PageTitle>Dossiers racine</PageTitle>
              <RootFoldersList />
            </div>
          </div>
        )}
      {authData.user.role === "admin" && (
        <AddEmailNotificationModal
          isOpen={addEmailNotificationModal}
          onClose={() => setAddEmailNotificationModal(false)}
          onSuccess={() => {
            getSettingsQuery.refetch();
            setAddEmailNotificationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Settings;
