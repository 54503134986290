import React from "react";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import classNames from "classnames";

const MenuItem = ({ children, path }) => {
  const location = useLocation();
  const history = useHistory();
  const isActive = location.pathname.startsWith(path);

  return (
    <div
      className={classNames("p-2 flex flex-auto justify-center py-3 ", {
        "bg-black text-white cursor-default": isActive,
        "bg-gray-100 text-black cursor-pointer": !isActive
      })}
      onClick={() => history.push(path)}
    >
      {children}
    </div>
  );
};

export default withRouter(MenuItem);
