import React from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "540px",
    transform: "translate(-50%, -50%)",
    borderRadius: "1rem",
    maxHeight: "100vh"
  },
};

const AppModal = ({ title, onClose, isOpen, children }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={title}
    >
      <div >

        <div onClick={onClose} className="flex justify-end">
          <span>
            <FaTimes className="cursor-pointer hover:text-gray-600" />
          </span>
        </div>
        <div className="pb-4 pl-4 pr-4">
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default AppModal;
