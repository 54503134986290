import React from "react";
import { useQuery } from "react-query";
import classnames from "classnames";

import { listUsers } from "../api/user.api";
import Button from "../components/Button";
import PageTitle from "../components/PageTitle";
import UserLine from "../components/UserLine";
import CreateUserModal from "../modals/CreateUserModal";
import Loader from "../components/Loader";
import { UserContext } from "../contexts/UserContext";
import UserDetailsModal from "../modals/UserDetailsModal";

const Users = () => {
  const [createUserModal, setCreateUserModal] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState(null)
  
  const { authData } = React.useContext(UserContext);

  const [page, setPage] = React.useState(1);

  const listUsersQuery = useQuery(["listUsers", page], listUsers, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col w-full h-full ">
      <div className="flex items-center justify-between flex-grow-0">
        <div>
          <PageTitle>Liste des utilisateurs</PageTitle>
        </div>

        {authData.user.role === "admin" && (
          <div>
            <Button onClick={() => setCreateUserModal(true)}>
              Créer un utilisateur
            </Button>
          </div>
        )}
      </div>

      <div className="flex-grow mt-2 bg-gray-100">
        {listUsersQuery.isLoading ? (
          <Loader />
        ) : (
          <>
            {listUsersQuery.isError ? (
              <div className="p-2">
                <div className="text-red-500">
                  {listUsersQuery.error.message}
                </div>
              </div>
            ) : (
              <>
                <div className="p-2">
                  {listUsersQuery.data.data.results.map((user) => (
                    <UserLine user={user} key={user._id} onSelect={(user) => setUserDetails(user)}/>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {!!userDetails && <UserDetailsModal user={userDetails} isOpen={!!userDetails} onClose={() => {
        setUserDetails(null);
        listUsersQuery.refetch();
      }} />}

      {!!listUsersQuery.data && (
        <div className={classnames("flex justify-between items-center mt-2")}>
          <Button
            size="sm"
            onClick={() => setPage((old) => Math.max(old - 1, 0))}
            isDisabled={listUsersQuery.isPreviousData || page === 1}
          >
            Précédent
          </Button>
          <div>
            <span>
              {`Page: ${page} / ${listUsersQuery.data.data.totalPages}`}
            </span>
          </div>
          <Button
            size="sm"
            onClick={() =>
              setPage((old) =>
                listUsersQuery.data.data.totalPages <=
                listUsersQuery.data.data.page
                  ? old
                  : old + 1
              )
            }
            isDisabled={
              listUsersQuery.isPreviousData ||
              listUsersQuery.data.data.totalPages <=
                listUsersQuery.data.data.page
            }
          >
            Suivant
          </Button>
        </div>
      )}

      {authData.user.role === "admin" && (
        <CreateUserModal
          isOpen={createUserModal}
          onClose={() => setCreateUserModal(false)}
          onSuccess={() => {
            listUsersQuery.refetch();
            setCreateUserModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Users;
