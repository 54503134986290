import React, {forwardRef} from "react";
import Loader from "../Loader";
import styled from "styled-components";
import {useTable, useRowSelect, useFlexLayout, useResizeColumns, useSortBy} from "react-table";
import {FolderColumns} from "./FolderColumns";
import Button from "../Button";
import classNames from "classnames";
import { zipFiles } from "../../api/filesystem.api";

const Styles = styled.div`
  padding: 1rem;
  ${'' /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
  ${'' /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;
  .table {
    display: block;
    border-spacing: 0;
  
    .thead {
       display: block;
       overflow-y: auto;
       overflow-x: hidden;
     }
  
    .tr {
       display: block;
       :last-child {
          .td {
             border-bottom: 0;
          }
       }
    }
  
    th {
       text-align: center;
    }
  
    .th,
    .td {
       margin: 0;
       padding: 0.5rem;
       border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  
       white-space: nowrap;
       overflow: hidden;
  
       position: relative;
  
       :last-child {
          border-right: 0;
       }
    }
  }
`

// const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

const downloadSelectedFiles = (selectedFlatRows) => {
  selectedFlatRows
    .map(row => row.original)
    .filter(file => !file.isDir)
    .forEach(file => window.open(file.location, '_blank'));
}

const downloadSelectedFilesAsZip = (selectedFlatRows) => {
  const files = selectedFlatRows
    .map(row => row.original)
    .filter(file => !file.isDir)
    .map(file => {
      return { name: file.name, parentFolderId: file.parentFolderId };
    });
  zipFiles({files});
}

const countSelectedFiles = (selectedFlatRows) => {
  return selectedFlatRows
    .map(row => row.original)
    .filter(file => !file.isDir)
    .length;
}

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const ReactTable = ({
                      files,
                      canWrite,
                      onPreviewPdf,
                      onSendByEmail,
                      onRename,
                      onMove,
                      onNote,
                      onAccessRights,
                      onRemove,
                    }) => {

  const sortTypes = {
    alphanumeric: (row1, row2, columnName) => {
      // const file1 = row1.original;
      // const file2 = row2.original;
      // if (file1.isDir !== file2.isDir) {
      //   return file1.isDir ? -1 : 1;
      // }
      const value1 = row1.values[columnName];
      const value2 = row2.values[columnName];
      if (isNaN(value1)) {
        return value1.toUpperCase() > value2.toUpperCase() ? 1 : -1;
      }
      return Number(value1) > Number(value2) ? 1 : -1;
    }
  };

  const columns = FolderColumns({
    canWrite,
    onPreviewPdf,
    onSendByEmail,
    onRename,
    onMove,
    onNote,
    onAccessRights,
    onRemove
  });

  const data = React.useMemo(() => {
    return files;
  }, [files]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable({
      columns,
      data,
      sortTypes
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 35,
          disableResizing: true,
          // eslint-disable-next-line react/display-name
          Header: ({getToggleAllRowsSelectedProps}) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // eslint-disable-next-line react/display-name
          Cell: ({row}) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns,
      ])
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0]
        selectionGroupHeader.canResize = false
      })
    }
  );

  const isFilesSelected = countSelectedFiles(selectedFlatRows) > 0;

  return (
    <>
      <div className="flex">
        <div className="mr-2 justify-self-end">
          <Button className={classNames({
                    "bg-red-500": isFilesSelected,
                    "bg-red-100": !isFilesSelected
                  })}
                  isDisabled={!isFilesSelected}
                  onClick={() => downloadSelectedFiles(selectedFlatRows)}>
            <span className="whitespace-nowrap">
              Télécharger {isFilesSelected ? countSelectedFiles(selectedFlatRows) : '_'} fichiers
            </span>
          </Button>
          <Button className={classNames('ml-4', {
                    "bg-red-500": isFilesSelected,
                    "bg-red-100": !isFilesSelected
                  })}
                  isDisabled={!isFilesSelected}
                  onClick={() => downloadSelectedFilesAsZip(selectedFlatRows)}>
            <span className="whitespace-nowrap">
              Zipper {isFilesSelected ? countSelectedFiles(selectedFlatRows) : '_'} fichiers
            </span>
          </Button>
        </div>
      </div>

      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
            >
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map(row => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div {...cell.getCellProps(cellProps)} className="td">
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}

/**
 *
 * @param data
 * @param onPreviewPdf
 * @param onNote
 * @returns {JSX.Element}
 * @constructor
 */
const FolderTable = ({data, onPreviewPdf, onSendByEmail, onRename, onMove, onNote, onAccessRights, onRemove}) => {
  const folders = data.folders.map(folder => {
    return {
      ...folder,
      isDir: true
    };
  })
  const files = data.files.map(file => {
    return {
      ...file,
      isDir: false
    }
  })

  return (
    <div className="flex flex-col flex-grow mt-2 overflow-scroll bg-gray-100">
      {!data ? (
        <div className="p-2">
          <Loader/>
        </div>
      ) : (
        !data.files.length && !data.folders.length && !data.canWrite ? (
          <div className="flex items-center justify-center h-48 p-2 text-gray-400 texttify-center">
            Pas de fichier dans ce dossier
          </div>
        ) : (
          <Styles>
            <ReactTable files={folders.concat(files)}
                        canWrite={data.canWrite}
                        onPreviewPdf={onPreviewPdf}
                        onSendByEmail={onSendByEmail}
                        onRename={onRename}
                        onMove={onMove}
                        onNote={onNote}
                        onAccessRights={onAccessRights}
                        onRemove={onRemove}
            />
          </Styles>
        )
      )}
    </div>
  );
};

export default FolderTable;
