import React from "react";
import moment from "moment";
import classnames from "classnames";
import { useQuery, useQueryClient } from "react-query";
import { UserContext } from "../contexts/UserContext";
import Button from "./Button";
import Loader from "./Loader";
import CreateRootFolderModal from "../modals/CreateRootFolderModal";
import { getRootFolders } from "../api/folder.api";
import { FaTrash } from "react-icons/fa";
import RemoveRootFolderModal from "../modals/RemoveRootFolderModal";

const RootFolderLine = ({ rootFolder, onDelete }) => {
  return (
    <div className="flex justify-between flex-grow px-2 py-3 border-b-2">
      <div className="font-medium w-60">{rootFolder.name}</div>
      <div className="text-sm text-gray-500">
        ajouté le {moment(rootFolder.createdAt).format("DD/MM/YYYY")}
      </div>
      <div className="flex">
        {/* <FaEdit className="mr-4 cursor-pointer" /> */}
        <FaTrash className="cursor-pointer text-red-400 hover:text-red-500" onClick={onDelete} />
      </div>
    </div>
  );
};

const RootFoldersList = () => {
  const { authData } = React.useContext(UserContext);
  const [page, setPage] = React.useState(1);
  const [rootFolderModal, setCreateRootFolderModal] = React.useState(false);
  const [folderToRemove, setFolderToRemove] = React.useState(null);

  const queryClient = useQueryClient();

  const getRootFoldersQuery = useQuery(["listRootFolders", page], getRootFolders, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  return (
    <div className="mt-3">
      {authData.user.role === "admin" && (
        <div>
          <Button onClick={() => setCreateRootFolderModal(true)}>
            Créer un client
          </Button>
        </div>
      )}

      <div className="flex-grow mt-2">
        {getRootFoldersQuery.isLoading ? (
          <Loader />
        ) : (
          <>
            {getRootFoldersQuery.isError ? (
              <div className="p-2">
                <div className="text-red-500">
                  {getRootFoldersQuery.error.message}
                </div>
              </div>
            ) : (
              <>
                <div className="p-2">
                  {getRootFoldersQuery.data.data.results.map((rootFolder) => (
                    <RootFolderLine rootFolder={rootFolder} key={rootFolder._id} onDelete={() => setFolderToRemove(rootFolder)} />
                  ))}
                </div>

                {!getRootFoldersQuery.data.data.results.length && (
                  <div className="p-2 text-gray-500">
                    Vous n'avez pas encore de client.
                  </div>
                )}
              </>
            )}

            {!!getRootFoldersQuery.data && (
              <div
                className={classnames("flex justify-between items-center mt-2")}
              >
                <Button
                  size="sm"
                  onClick={() => setPage((old) => Math.max(old - 1, 0))}
                  isDisabled={getRootFoldersQuery.isPreviousData || page === 1}
                >
                  Précédent
                </Button>
                <div>
                  <span>
                    {`Page: ${page} / ${getRootFoldersQuery.data.data.totalPages}`}
                  </span>
                </div>
                <Button
                  size="sm"
                  onClick={() =>
                    // Here, we use `latestData` so the Next Page
                    // Button isn't relying on potentially old data
                    setPage((old) =>
                      getRootFoldersQuery.data.data.totalPages <=
                        getRootFoldersQuery.data.data.page
                        ? old
                        : old + 1
                    )
                  }
                  isDisabled={
                    getRootFoldersQuery.isPreviousData ||
                    getRootFoldersQuery.data.data.totalPages <=
                    getRootFoldersQuery.data.data.page
                  }
                >
                  Suivant
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <CreateRootFolderModal
        isOpen={rootFolderModal}
        onClose={() => {
          setCreateRootFolderModal(false);
        }}
        onSuccess={() => {
          setCreateRootFolderModal(false);
          queryClient.invalidateQueries("listRootFolders");
        }}
      />

      <RemoveRootFolderModal isOpen={!!folderToRemove}
        onClose={() => {
          setFolderToRemove(null);
        }}
        folder={folderToRemove}
        onSuccess={() => {
          setFolderToRemove(null);
          queryClient.invalidateQueries("listRootFolders");
        }} />
    </div>
  );
};

export default RootFoldersList;
