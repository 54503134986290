import axios from "axios";

export const getDashboardData = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/dashboard`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    }
  });
};
