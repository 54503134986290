import React from "react";
import classnames from "classnames";

const Input = ({
  label,
  name,
  error,
  inputRef,
  className,
  type = "text",
  inputProps = {},
}) => {
  return (
    <div className={className}>
      <div>{!!label && <label className="font-medium">{label}</label>}</div>
      <div>
        <input
          {...inputProps}
          ref={inputRef}
          type={type}
          name={name}
          className={classnames(
            "block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner",
            {
              "border-red-500 border-2": !!error,
            }
          )}
        />
      </div>

      {!!error && <div className="text-red-500 text-sm">{error.message}</div>}
    </div>
  );
};

export default Input;
