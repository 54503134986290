import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";

const Login = () => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const { login, authData } = useContext(UserContext);


  useEffect(() => {
    if (authData.isAuthenticated) {
      history.replace("/tableau-de-bord");
    }
  }, [authData]);

  const onSubmit = async (values) => {
    login(values);
  };

  return (
    <div className="grid min-h-screen place-items-center">
      <div className="w-11/12 p-12 bg-white shadow sm:w-8/12 md:w-1/2 lg:w-5/12">
        <div className="text-center">
          <img
            src="/logo.png"
            alt="MyDataCloud"
            className="mx-auto h-60"
          />
        </div>
        <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
          <label
            htmlFor="email"
            className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
          >
            E-mail
          </label>
          <input
            id="email"
            type="email"
            name="email"
            ref={register({
              required: "Ce champ est obligatoire"
            })}
            placeholder="Entrez votre email"
            autoComplete="email"
            className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"

            required
          />
          {!!errors.email && (
            <div className="text-red-500">{errors.email.message}</div>
          )}
          <label
            htmlFor="password"
            className="block mt-2 text-xs font-semibold text-gray-600 uppercase"
          >
            Mot de passe
          </label>
          <input
            id="password"
            type="password"
            name="password"
            ref={register({
              required: "Ce champ est obligatoire"
            })}
            placeholder="Entrez votre mot de passe"
            autoComplete="new-password"
            className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
            required
          />

          {!!errors.password && (
            <div className="text-red-500">{errors.password.message}</div>
          )}

          {!!authData.error && (
            <div className="mt-2 text-red-500">
              {/* {authData.error.message} */}
              Email ou mot de passe invalide
            </div>
          )}

          <div className="mt-2 text-right">

            <Button
              type="submit"
              isDisabled={
                authData.isLoading
              }>

              {authData.isLoading ? "Connexion en cours..." : "Se logger"}
            </Button>
          </div>

        </form>
      </div>
    </div >
  );
};

export default Login;
