import React from "react";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { useParams, withRouter } from 'react-router-dom'
import { useQuery } from "react-query";
import classNames from "classnames";
import { getFolders } from "../api/folder.api";
import Loader from "./Loader";
import PageTitle from "./PageTitle";
import _ from 'underscore'

const hasChildren = (folder, folderId) => {
  return _.some(folder.children, child => child._id === folderId || hasChildren(child, folderId))
}

const Folder = withRouter(({ folder, history }) => {
  const { folderId } = useParams()
  const isOpen = folder._id === folderId || hasChildren(folder, folderId)
  return (
    <div className="my-4 ml-3">
      <div
        className={classNames("flex ", {
          "text-gray-800 hover:text-gray-900 cursor-pointer":
            folder._id !== folderId,
          "text-red-500": folder._id === folderId,
        })}
        onClick={() => {
          history.push(`/repertoires/${folder._id}`);
        }}
      >
        <div className="mr-4">
          {isOpen ? (
            <FaFolderOpen
              className="text-2xl"
              style={{ position: "relative", bottom: "2px", color: "#F7D383" }}
            />
          ) : (
            <FaFolder
              className="text-2xl"
              style={{ position: "relative", bottom: "2px", color: "#F7D383" }}
            />
          )}
        </div>
        <div className="font-medium whitespace-nowrap">{folder.name}</div>
      </div>
      {isOpen && (
        <div>
          {folder.children.map((child) => (
            <Folder key={child._id} folder={child} />
          ))}
        </div>
      )}
    </div>
  );
});

const FolderNavigation = ({ history }) => {
  const getFoldersQuery = useQuery("folders", getFolders);
  return (
    <div className="flex-grow-0 flex-shrink-0 mr-3 bg-gray-100 w-72">
      <div className="h-full py-4 overflow-scroll">
        <div className="px-4">
          <PageTitle>
            <div onClick={() => history.push('/repertoires')}>
              Navigation
            </div>
          </PageTitle>

        </div>

        <div className="h-px px-4 mt-3">
          {getFoldersQuery.isLoading ? (
            <Loader />
          ) : (
            <div>
              {getFoldersQuery.data?.data && (
                <div>
                  {getFoldersQuery.data.data.map((folder) => (
                    <Folder key={folder._id} folder={folder} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FolderNavigation);
