import React from 'react';
import {FaTrash} from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSettings, removeDefaultFolder, addDefaultFolder } from '../api/settings.api';
import Button from './Button';
import Loader from './Loader';
import PageTitle from './PageTitle';

const DefaultFolder = ({ defaultFolder, parentName, removeMutation, isRoot }) => {
  const className = isRoot ? "flex border-b-2 border-gray-200 px-2 py-2" : "flex border-b-2 border-gray-200 ml-4 px-2 py-2";
  return (
    <div className={className}>
      <div className="flex-grow">
        {defaultFolder.name}
      </div>
      <div>
        <FaTrash onClick={() => removeMutation.mutate({ name: defaultFolder.name, parentName: parentName })} className="text-red-400 hover:text-red-500 cursor-pointer"/>
      </div>
    </div>
  )
}

const RootFolder = ({ defaultFolder, removeMutation }) => {
  return DefaultFolder({ defaultFolder, removeMutation, isRoot: true});
}

const ChildFolder = ({ defaultFolder, removeMutation, parentName }) => {
  return DefaultFolder({ defaultFolder, parentName, removeMutation, isRoot: false});
}

const AddDefaultFolder = ({ parentName, isRoot }) => {
  const queryClient = useQueryClient()
  const [newFolderName, setNewFolderName] = React.useState("")

  const addDefaultFolderMutation = useMutation(addDefaultFolder, {
    onSuccess: () => {
      setNewFolderName('')
      queryClient.invalidateQueries("settings");
    }
  })

  const _addDefaultFolder = () => {
    addDefaultFolderMutation.mutate({ name: newFolderName, parentName: parentName })
  }

  const placeholder = isRoot ? "Nouveau dossier par défaut" : "Sous-dossier";
  const className = isRoot ? "flex mt-10 flew-grow" : "flex ml-4 mt-2 mb-6 flew-grow";

  return (
    <div className={className}>
      <input
        name="newDefaultFolder"
        placeholder={placeholder}
        value={newFolderName}
        onChange={e => {
          setNewFolderName(e.currentTarget.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            _addDefaultFolder()
          }
        }

        } className="border-2 border-gray-400 flex-grow py-1 px-2" />
      <Button
        isDisabled={!newFolderName}
        onClick={() => _addDefaultFolder()}>Ajouter</Button>
    </div>
  )
}

const AddChildFolder = ({ parentName }) => {
  return AddDefaultFolder({ parentName, isRoot: false})
}

const AddRootFolder = () => {
  return AddDefaultFolder({ isRoot: true})
}

const DefaultFolders = () => {
  const getSettingsQuery = useQuery("settings", getSettings);
  const queryClient = useQueryClient()

  const removeDefaultFolderMutation = useMutation(removeDefaultFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
    }
  })

  const defaultFolders = getSettingsQuery.data.data.defaultFolders;
  return (
    <div>
      <PageTitle>
        Dossier par défaut
      </PageTitle>

      {getSettingsQuery.isLoading ? (
        <Loader />
      ) : (
          <div>
            {
              defaultFolders && (
                <div>
                  {
                    defaultFolders.length ? (
                      <div>
                        {
                          defaultFolders.map(defaultFolder => (
                            <div key={defaultFolder.name}>
                              <RootFolder defaultFolder={defaultFolder} removeMutation={removeDefaultFolderMutation} />
                              {
                                defaultFolder.children && defaultFolder.children.map(childFolder => (
                                  <ChildFolder key={childFolder.name}
                                                 defaultFolder={childFolder}
                                                 parentName={defaultFolder.name}
                                                 removeMutation={removeDefaultFolderMutation} />
                                ))
                              }
                              <AddChildFolder parentName={defaultFolder.name} />
                            </div>
                          ))
                        }

                      </div>
                    ) : (
                        <div className="text-gray-400">
                          Vous n'avez pas de dossier par défaut
                        </div>
                      )
                  }
                </div>
              )
            }
          </div>
        )}
      <AddRootFolder />
    </div>
  );
};

export default DefaultFolders;
