import React from "react";
import { useQuery } from "react-query";
import { getFolderAccessRights, updateAccessRights } from "../api/folder.api";
import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { UserContext } from "../contexts/UserContext";
import _ from 'underscore'

// eslint-disable-next-line no-unused-vars
const AddUserAccessRight = ({ users, onAddUser }) => {
  const [userSelected, setUserSelected] = React.useState(null)

  if (!users.length) return null

  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold">Ajouter un utilisateur</h3>


      <div className="flex">
        <select className="flex-grow" onChange={(e) => setUserSelected(_.findWhere(users, { userId: e.currentTarget.value }))}>
          <option value="">Sélectionner un utilisateur</option>
          {
            users.map(user => (
              <option key={user.userId} value={user.userId}>{user.name}</option>
            ))
          }
        </select>

        <Button isDisabled={!userSelected} onClick={() => {
          setUserSelected(null);
          onAddUser(userSelected)
        }}>Ajouter</Button>
      </div>
    </div>
  )
}

const AccessRightsModalContent = ({ onSuccess, folderId }) => {
  console.log(folderId);
  const [accessRights, setAccessRights] = React.useState([])
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = React.useState([])
  // eslint-disable-next-line no-unused-vars
  const { authData } = React.useContext(UserContext);
  const getAccessRightQuery = useQuery(
    ["accessRights", folderId],
    getFolderAccessRights,
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setAccessRights(data.data.accessRights)
        setUsers(data.data.users)
      }
    }
  );

  return (
    <div>
      <div className="mt-3">
        {
          getAccessRightQuery.isLoading ? (
            <Loader />
          ) : (
              <div>
                <div>
                  {
                    accessRights.map(userAccessRights => {
                      return (
                        <div className="flex justify-between px-2 py-2 border-b-2" key={userAccessRights.userId}>
                          <div>{userAccessRights.name}</div>
                          <div>
                            <select defaultValue={userAccessRights.accessRights} onChange={e => {
                              const newUserAccesss = {
                                ...userAccessRights,
                                accessRights: e.currentTarget.value
                              }

                              const newAccessRights = [...accessRights.filter(a => a.userId !== userAccessRights.userId), newUserAccesss]
                              setAccessRights(newAccessRights)
                            }}>
                              <option value="none">Aucun</option>
                              <option value="read">Lecture</option>
                              <option value="readwrite">Lecture et écriture</option>
                            </select>
                          </div>
                        </div>
                      )
                    })
                  }

                  {
                    !!getAccessRightQuery.data?.data?.accessRights && (
                      <>
                        {
                          accessRights !== getAccessRightQuery.data?.data?.accessRights && (
                            <div className="mt-2 text-right">
                              <Button size="sm" onClick={() => {
                                updateAccessRights(folderId, accessRights)
                                onSuccess()
                              }}>Sauvegarder et fermer</Button>
                            </div>
                          )
                        }
                      </>
                    )
                  }


                </div>
                {
                  authData.user.role === "admin" && (
                    <AddUserAccessRight users={users} onAddUser={user => {
                      setUsers(users.filter(u => u.userId !== user.userId))
                      setAccessRights([...accessRights, { ...user, accessRights: 'none' }])
                    }} />
                  )
                }
                </div>
            )
        }
      </div>
    </div >
  )
}

const AccessRightsModal = ({ isOpen, onClose, file, onSuccess }) => {
  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Droits d'accès</h3>
        {isOpen && (
          <AccessRightsModalContent onSuccess={onSuccess} folderId={file._id} />
        )}
      </div>
    </AppModal>
  );
};

export default AccessRightsModal;
