import {
  FaFile,
  FaRegFileVideo,
  FaFilePdf,
  FaRegFileImage,
  FaRegFilePowerpoint,
  FaFileWord,
  FaFileExcel,
  FaFileCsv,
  FaFileAlt,
  FaFileAudio,
} from "react-icons/fa";

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const renderFileIcon = (file) => {
  if (
    file.mimeType === "image/png" ||
    file.mimeType === "image/jpeg" ||
    file.mimeType === "image/gif"
  ) {
    return <FaRegFileImage style={{ color: "#0078D7" }} />;
  }

  if (file.mimeType === "application/pdf") {
    return <FaFilePdf style={{
      color: "#CB0606"
    }} />;
  }

  if (file.mimeType === "text/csv") {
    return <FaFileCsv style={{ color: "#BDC3C7" }} />;
  }

  if (file.mimeType === "application/rtf" || file.mimeType === "text/plain") {
    return <FaFileAlt style={{ color: "#BDC3C7" }} />;
  }

  if (
    file.mimeType === "application/vnd.ms-powerpoint" ||
    file.mimeType ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ) {
    return <FaRegFilePowerpoint style={{color: "#E04006"}}/>;
  }

  if (
    file.mimeType === "application/msword" ||
    file.mimeType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return <FaFileWord style={{ color: "#2B5797" }} />;
  }

  if (
    file.mimeType === "application/vnd.ms-excel" ||
    file.mimeType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return <FaFileExcel style={{ color: '#2E7D32' }} />;
  }

  if (file.mimeType === "video/mpeg" || file.mimeType === "video/webm") {
    return <FaRegFileVideo />;
  }

  if (file.mimeType === "audio/mp3" || file.mimeType === "audio/mpeg") {
    return <FaFileAudio />;
  }

  return <FaFile />;
};
