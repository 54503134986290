import React from "react";
import { withRouter } from "react-router-dom";
import { formatBytes } from "../lib/utils";
import moment from "moment";
import FileName from "./FileName";

const LastFileLine = ({ file, history }) => {
  if (file.status === "uploading") return null
  return (
    <tr className="border-b-2">
      <td
        className="px-4 py-3 font-medium text-left cursor-pointer hover:underline"
        onClick={() => history.push(`/repertoires/${file.clientName.folderId}`)}
      >
        {file.clientName?.name}
      </td>
      <td className="px-4 py-3 font-medium text-left cursor-pointer hover:underline">
        <FileName item={file} type="file" />
      </td>
      <td className="px-4 py-3 text-center">{file.owner}</td>
      <td className="px-4 py-3 text-center">{formatBytes(file.size)}</td>
      <td
        className="px-4 py-3 font-medium text-center cursor-pointer hover:underline"
        onClick={() => history.push(`/repertoires/${file.parentFolderId}`)}
      >
        {file.folderName}
      </td>
      <td className="px-4 py-3 text-center">
        {moment(file.updatedAt).format("DD/MM/YYYY à HH:mm")}
      </td>
    </tr>
  );
};

export default withRouter(LastFileLine);
