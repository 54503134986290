import React from "react";
import ReactLoader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader = ({ title }) => {
  return (
    <div className="flex flex-col items-center justify-center h-32">
      <ReactLoader
        type="TailSpin"
        color="#EF4444"
        height={40}
        width={40}
      // timeout={3000} 
      />
      <div className="text-gray-400">{title || "Chargement..."}</div>
    </div>
  );
};

export default Loader;
