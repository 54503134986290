import React from "react";
import { useMutation } from "react-query";
import { editNoteFolder } from "../api/folder.api";
import { editNoteFile } from "../api/file.api";
import { useParams } from 'react-router-dom'

import AppModal from "../components/AppModal";
import Button from "../components/Button";

const NewNoteModal = ({ isOpen, onClose, file, onSuccess }) => {
  let note = file ? file.note : null;
  const isDir = file ? file.isDir : true;

  const createNoteMutation = useMutation(isDir ? editNoteFolder : editNoteFile, {
    onSuccess,
  });
  const params = useParams()
  const id = file ? file._id : params.folderId
  const name = file ? file.name : ''

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <span className="mr-2 text-xl font-semibold">Créer une note sur</span>
        <span className='text-xl font-semibold text-red-400'>{name}</span>
        {isOpen && (
          <form>
            <div className="mt-2">
              <textarea 
                className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" 
                onChange={e => note = e.currentTarget.value}
                defaultValue={note}
              />
            </div>

            {!!createNoteMutation.error && (
              <div className="text-red-500">
                {createNoteMutation.error.message}
              </div>
            )}

            <div className="mt-4 text-right">
              <Button
                onClick={() => {
                  createNoteMutation.mutate({ note: note, id });
                  onClose();
                }}
                isDisabled={createNoteMutation.isLoading}
              >
                {createNoteMutation.isLoading
                  ? "Création d'une note..."
                  : "Valider et créer"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default NewNoteModal;
