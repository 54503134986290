import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalOrExternalPlusFn} from "../../../lib/roleUtils";

export const DropdownItemDelete = ({item, role, canWrite, onRemove}) => {
  return isAdminOrInternalOrExternalPlusFn(role) && canWrite ? (
    <DropdownItem
      title="Supprimer"
      onClick={() => {
        onRemove(item);
      }}
    />
  ) : <></>;
}