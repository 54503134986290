import axios from "axios";

export const addFileInFolder = async (folderId, formData) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${folderId}/upload-file`,
    data: formData,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

export const sendFileByEmail = async (values) => {
  const { fileId, ...rest } = values
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/files/${fileId}/send-by-email`,
    data: rest,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
    },
  });
}

export const addFiles = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/files`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const getFiles = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/files`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const getLastFilesAdded = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/files/last-added`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const removeFile = (fileId) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/v1/files/${fileId}`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const renameFile = (values) => {
  const { fileId, ...rest } = values;
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/files/${fileId}`,
    data: rest,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const moveFile = (values) => {
  const { fileId, ...rest } = values;
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/files/${fileId}/move`,
    data: rest,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const editNoteFile = (param) => {
  const { id } = param
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/files/${id}/note`,
    data: param,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
}
