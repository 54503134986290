import { useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import Avatar from "../components/Avatar";
import "reactjs-popup/dist/index.css";

import { UserContext } from "../contexts/UserContext";

const NavBar = () => {
  const history = useHistory();
  const { logout, authData } = useContext(UserContext);
  return (
    <div className="flex justify-between items-center">
      <div>
        <img
          src="/logo.png"
          alt="MyDataCloud"
          className="mx-auto"
          style={{
            height: "100px"
          }}
        />
      </div>

      {!!authData && !!authData.user && (
        <div className="flex items-center">
          <div className="mr-2 font-medium">{authData.user.name}</div>
          <Popup
            trigger={
              <div>
                <Avatar name={authData.user.name} />
              </div>
            }
            position="bottom right"
            on="click"
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: "0px", border: "none" }}
            arrow={true}
          >
            <div className="p-2">
              <div
                className="p-2 hover:bg-gray-100	cursor-pointer"
                onClick={() => {
                  logout();
                  history.replace("/login");
                }}
              >
                Se déconnecter
              </div>
            </div>
          </Popup>
        </div>
      )}
    </div>
  );
};

export default withRouter(NavBar);
