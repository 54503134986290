import axios from "axios";

export const createFolder = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/folders`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const editNoteFolder = (param) => {
  const { id } = param
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${id}/note`,
    data: param,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
}

export const createRootFolder = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/root-folders`,
    data: values,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const getRootFolders = (ctx) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/root-folders?page=${ctx.queryKey[1]}&limit=10`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const getFolders = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/folders`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const updateAccessRights = (folderId, accessRights) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${folderId}/access-rights`,
    data: accessRights,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
}

export const getFolderAccessRights = (ctx) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${ctx.queryKey[1]}/access-rights`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
}

export const listFolder = (ctx) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${ctx.queryKey[1] || "root"
      }`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const getBreadcrumb = (ctx) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${ctx.queryKey[1] || "root"
      }/breadcrumb`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const removeFolder = (folderId) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${folderId}`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const renameFolder = (values) => {
  const { folderId, ...rest } = values;
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${folderId}`,
    data: rest,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const moveFolder = (values) => {
  const { folderId, ...rest } = values;
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/folders/${folderId}/move`,
    data: rest,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};
