import React from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {renameFile} from "../api/file.api";
import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Input from "../components/Input";
import {renameFolder} from "../api/folder.api";

const RenameModal = ({ isOpen, onClose, file, onSuccess }) => {
  const { register, handleSubmit, errors } = useForm();
  const label = file ? (file.isDir ? 'dossier' : 'fichier') : 'fichier';
  const rename = file ? (file.isDir ? renameFolder : renameFile) : renameFile;
  const renameMutation = useMutation(rename, {
    onSuccess,
  });

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Modification du {label}</h3>
        {isOpen && (
          <form
            onSubmit={handleSubmit((values) => {
              if (file.isDir) {
                renameMutation.mutate({...values, folderId: file._id});
              } else {
                renameMutation.mutate({...values, fileId: file._id});
              }
            })}
          >
            <div>
              <Input
                label={"Nom du " + label}
                name="name"
                error={errors.name}
                inputRef={register({
                  required: "Ce champ est obligatoire",
                })}
                inputProps={{
                  defaultValue: file.name,
                }}
              />

              {!!renameMutation.error && (
                <div className="text-red-500">
                  {renameMutation.error.message}
                </div>
              )}
              <div className="mt-4 text-right">
                <Button
                  isDisabled={renameMutation.isLoading}
                  type="submit"
                >
                  {renameMutation.isLoading
                    ? "Modification en cours..."
                    : "Modifier le " + label}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default RenameModal;
