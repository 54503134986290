import React from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from 'react-router-dom'
import asyncPool from "tiny-async-pool";
import { addFileInFolder } from "../api/file.api";
import AppModal from "../components/AppModal";
import Loader from "../components/Loader";

const AddFilesModal = ({ isOpen, onClose, onSuccess }) => {
  const { folderId } = useParams()
  const [loading, setLoading] = React.useState(false);

  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      setLoading(true);

      async function uploadPromise(file) {
        return new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append("file", file);
          try {
            addFileInFolder(folderId, formData).then(resolve)
          } catch (e) {
            reject(e)
          }
        })
      }

      return asyncPool(1, acceptedFiles, uploadPromise).then(() => {
        setLoading(false)
        onSuccess();
      });
    },
    [onSuccess, folderId]
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ onDrop, maxSize: 1048576 * 127 }); // 127Mo limit

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold ">Déposer vos fichiers</h3>
        {isOpen && (
          <>
            {loading ? (
              <div className="flex items-center justify-center h-60">
                <div className="flex items-center justify-center flex-grow" style={{ minHeight: '60px' }}>
                  <Loader title="Téléversement de vos fichiers..." />
                </div>
              </div>
            ) : (
              <>
                {
                  fileRejections && fileRejections[0] && fileRejections[0].errors[0]?.code === 'file-too-large' && (
                    <div className="mt-3 text-center text-red-400">
                      La taille limite des fichiers est de 100 Mo
                    </div>
                  )
                }
                <div
                  {...getRootProps()}
                  className="flex items-center px-8 py-4 mt-2 italic border-2 border-dashed h-60 text-medium"
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Déposez vos fichiers ici ...</p>
                  ) : (
                    <p className="font-medium text-gray-500">
                      Déposez des fichiers ici, ou cliquez pour sélectionner des
                      fichiers
                    </p>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </AppModal>
  );
};

export default AddFilesModal;
