import { useQuery } from "react-query";
import { getLastFilesAdded } from "../api/file.api";
import DashboardWidgets from "../components/DashboardWidgets";
import LastFileLine from "../components/LastFileLine";
import Loader from "../components/Loader";
import PageTitle from "../components/PageTitle";

const Dashboard = () => {
  const getLastFilesAddedQuery = useQuery("lastFilesAdded", getLastFilesAdded);
  
  return (
    <div className="w-full">
      <DashboardWidgets />

      <div className="mt-3">
        <PageTitle>Derniers ajouts</PageTitle>
        <div className="p-3 mt-2 bg-gray-100">
          {getLastFilesAddedQuery.isLoading ? (
            <Loader />
          ) : (
            <table className="w-full mt-2 table-auto">
              <thead className="border-b-2">
                <tr>
                  <th>Client</th>
                  <th>Nom du fichier</th>
                  <th>Propriétaire</th>
                  <th>Taille</th>
                  <th>Dossier</th>
                  <th>Date de modification</th>
                </tr>
              </thead>
              {!!getLastFilesAddedQuery.data?.data && (
                <tbody>
                  {getLastFilesAddedQuery.data.data.map((file) => (
                    <LastFileLine key={file._id} file={file} />
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
