import { Switch, Redirect, BrowserRouter, HashRouter } from "react-router-dom";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Files from "./pages/Files";
import Users from "./pages/Users";
import Settings from "./pages/Settings";

import NonAuthMiddleware from "./middlewares/NonAuthMiddleware";
import CheckRoleMiddleware from "./middlewares/CheckRoleMiddleware";

// Sentry.init({
//   dsn:
//     "https://fc87426cc92c4e059e61ff2544a36ad5@o354370.ingest.sentry.io/5530376",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const roles = ['admin', 'internal_user', 'external_user+', 'external_user'];

const queryClient = new QueryClient();

const App = () => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Switch>
          <NonAuthMiddleware exact path={"/connexion"} component={Login} />
          <CheckRoleMiddleware
            roles={["admin", "internal_user"]}
            exact
            path={"/tableau-de-bord"}
            component={Dashboard}
          />
          <CheckRoleMiddleware
            exact
            roles={roles}
            path={"/repertoires"}
            component={Files}
          />
          <CheckRoleMiddleware
            exact
            roles={roles}
            path={"/repertoires/:folderId"}
            component={Files}
          />
          <CheckRoleMiddleware
            roles={["admin", "internal_user"]}
            exact
            path={"/utilisateurs"}
            component={Users}
          />
          <CheckRoleMiddleware
            roles={["admin", "internal_user"]}
            exact
            path={"/configuration"}
            component={Settings}
          />
          <Redirect to="/connexion" />
        </Switch>
      </HashRouter>

      {process.env.REACT_APP_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </BrowserRouter>
);

export default App;
