import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalFn} from "../../../lib/roleUtils";

export const DropdownItemCreateNote = ({item, role, onNote}) => {
  return isAdminOrInternalFn(role) ? (
    <DropdownItem
      title="Créer une note"
      onClick={() => {
        console.log(item);
        onNote(item);
      }}
    />
  ) : <></>;
}