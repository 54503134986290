import React from "react";
import { useQuery } from "react-query";
import { getDashboardData } from "../api/dashboard.api";
import { formatBytes } from "../lib/utils";

const DashboardWidgets = () => {
  const getDashboardDataQuery = useQuery("dashboardData", getDashboardData);

  if (getDashboardDataQuery.isLoading) return null;

  if (!getDashboardDataQuery.data?.data) return null;

  return (
    <div className="grid grid-cols-3 gap-3">
      <div className="bg-gray-100 flex-grow flex-shrink-0 mr-3 justify-center items-center flex h-32">
        <div className="text-center">
          <div className="text-2xl font-medium">
            {formatBytes(getDashboardDataQuery.data.data.size)}
          </div>
          <div className="text-gray-500">Espace disque utilisé</div>
        </div>
      </div>
      <div className="bg-gray-100 flex-grow flex-shrink-0 mr-3 justify-center items-center flex h-32">
        <div className="text-center">
          <div className="text-2xl font-medium">
            {getDashboardDataQuery.data.data.usersCount}
          </div>
          <div className="text-gray-500">Utilisateurs créés</div>
        </div>
      </div>
      <div className="bg-gray-100 flex-grow flex-shrink-0 justify-center items-center flex h-32">
        <div className="text-center">
          <div className="text-2xl font-medium">
            {getDashboardDataQuery.data.data.clientsCount}
          </div>
          <div className="text-gray-500">Clients</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWidgets;
