import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { getFolders } from "../api/folder.api";

import { createUser } from "../api/user.api";

import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Input from "../components/Input";

const RoleRadio = ({value, label, callback}) => {
  return (
    <div>
      <label>
        <input
          name="role"
          type="radio"
          value={value}
          ref={callback({ required: "Ce champ est requis" })}
        />
        <span className="ml-1">{label}</span>
      </label>
    </div>
  );
}

const CreateUserModal = ({ isOpen, onClose, onSuccess }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const getFoldersQuery = useQuery("folders", getFolders);

  const watchRole = watch("role");

  const createUserMutation = useMutation(createUser, {
    onSuccess,
  });

  const inputProps = {
    autoFocus: true
  };

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Créer un utilisateur</h3>
        {isOpen && (
          <form onSubmit={handleSubmit(createUserMutation.mutate)}>
            <div className="mt-2">
              <Input
                label="Nom et Prénom"
                inputRef={register({
                  required: "Ce champ est obligatoire",
                })}
                error={errors.name}
                name="name"
                inputProps={inputProps}
              />

              <Input
                className="mt-2"
                label="Email"
                type="email"
                inputRef={register({
                  required: "Ce champ est obligatoire",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "L'adresse email est invalide",
                  },
                })}
                error={errors.email}
                name="email"
              />

              <Input
                className="mt-2"
                type="password"
                label="Mot de passe"
                inputRef={register({
                  required: "Ce champ est obligatoire",
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i,
                    message:
                      "Votre mot de passe doit faire au moins 8 caractères et doit contenir au moins 1 lettre minuscule, 1 lettre majuscule et 1 chiffre",
                  },
                })}
                error={errors.password}
                name="password"
              />

              <div className="mt-2">
                <div>
                  <label className="font-medium">Rôle de l'utilisateur</label>
                </div>
                <div className="flex flex-col">
                  <RoleRadio value="admin" label="Administrateur" callback={register} />
                  <RoleRadio value="internal_user" label="Utilisateur interne" callback={register} />
                  <RoleRadio value="external_user+" label="Utilisateur externe +" callback={register} />
                  <RoleRadio value="external_user" label="Utilisateur externe" callback={register} />
                </div>

                {!!errors.role && (
                  <div className="text-sm text-red-500">
                    {errors.role.message}
                  </div>
                )}
              </div>
              {/* TODO: All FolderId are send... */}
              {
                (watchRole === 'external_user' || watchRole === 'external_user+') && (
                  <div className="mt-2">
                    <div>
                      <label className="font-medium">Accès dossier client</label>
                    </div>
                    <div className="grid grid-cols-2">
                      {getFoldersQuery.data.data.map((folder, i) => (
                        <div key={folder._id} >
                          <label>
                            <input
                              name={`clientFolderId[${i}]`}
                              type="checkbox"
                              value={folder._id}
                              ref={register()}
                            />
                            <span>{folder.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              }
            </div>

            {!!createUserMutation.error && (
              <div className="text-red-500">
                {createUserMutation.error.message}
              </div>
            )}

            <div className="mt-4 text-right">
              <Button type="submit" isDisabled={createUserMutation.isLoading}>
                {createUserMutation.isLoading
                  ? "Création de l'utilisateur..."
                  : "Valider et créer"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default CreateUserModal;
