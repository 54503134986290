import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createFolder } from "../api/folder.api";
import { useParams } from 'react-router-dom'

import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Input from "../components/Input";

const NewFolderModal = ({ isOpen, onClose, onSuccess }) => {
  const { register, handleSubmit, errors } = useForm();

  const createFolderMutation = useMutation(createFolder, {
    onSuccess,
  });

  const { folderId } = useParams()

  const inputProps = {
    autoFocus: true
  };

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Créer un nouveau dossier</h3>
        {isOpen && (
          <form
            onSubmit={handleSubmit((values) =>
              createFolderMutation.mutate({
                folder: {
                  ...values
                },
                parentFolderId: folderId,
              })
            )}
          >
            <div className="mt-2">
              <Input
                label="Nom du dossier"
                inputRef={register({
                  required: "Ce champ est obligatoire",
                })}
                error={errors.folderName}
                name={"name"}
                inputProps={inputProps}
              />
            </div>

            {!!createFolderMutation.error && (
              <div className="text-red-500">
                {createFolderMutation.error.message}
              </div>
            )}

            <div className="mt-4 text-right">
              <Button type="submit" isDisabled={createFolderMutation.isLoading}>
                {createFolderMutation.isLoading
                  ? "Création du dossier..."
                  : "Valider et créer"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default NewFolderModal;
