import Popup from "reactjs-popup";
import {FaFileAlt} from "react-icons/fa";
import React from "react";

export const FolderNote = ({file, onNote}) => {
  return <div
    className='flex flex-row items-center justify-center'
    onClick={() => {
      onNote(file);
    }}
  >
    <Popup
      key={`popover_${file._id}`}
      trigger={
        <div>
          <FaFileAlt className='mr-2 cursor-pointer hover:text-gray-800'/>
        </div>
      }
      position="top center"
      on="hover"
      closeOnDocumentClick
      closeOnEscape
    >
      <span>{file.note}</span>
    </Popup>
  </div>;
}