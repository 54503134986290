import React from "react";
import classnames from "classnames";

const Button = ({
  children,
  type = "button",
  isDisabled = false,
  onClick,
  outline,
  color = "black",
  className = "",
  size = "md"
}) => {
  return (
    <button
      disabled={isDisabled}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          return;
        }

        if (onClick) onClick();
      }}
      type={type}
      className={classnames("font-semibold", className, {
        "bg-gray-100 cursor-default": color === "black" && isDisabled,
        "bg-red-200 text-white cursor-default": color === "red" && isDisabled,
        "text-black border-2 border-gray-100 bg-gray-200":
          color === "black" && outline,
        "bg-black text-white": color === "black" && !outline && !isDisabled,
        "bg-red-500 text-white": color === "red" && !outline && !isDisabled,
        "py-2 px-2.5 text-sm": size === 'sm',
        "py-2.5 px-5": size === "md"
      })}
    >
      <div className="flex items-center">{children}</div>
    </button>
  );
};

export default Button;
