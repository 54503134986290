import React from "react";
import { FaFolder } from "react-icons/fa";
import { withRouter } from 'react-router-dom'

import { formatBytes, renderFileIcon } from "../lib/utils";

const shortFileName = fileName => {
  if (fileName.length > 50) return `${fileName.substr(0, 20)}...${fileName.substr(fileName.length - 20)}`
  return fileName
}

const FileName = ({ item, history, onPreviewPdf }) => {
  return (
    <div
      className="flex items-center cursor-pointer hover:underline"
      onClick={() => {
        if (item.isDir) {
          history.push(`/repertoires/${item._id}`)
        } else {
          if (item.mimeType === "application/pdf") {
            onPreviewPdf ? onPreviewPdf(item) : window.open(item.location, "_blank");
          } else {
            window.open(item.location, "_blank");
          }
        }
      }}
    >
      <div
        className="mr-2 text-2xl"
        style={{ position: "relative", bottom: "1px" }}
      >
        {item.isDir ? <FaFolder style={{ color: "#F7D383" }} /> : renderFileIcon(item)}
      </div>
      {item.isDir ? (
        <div className="font-medium">{shortFileName(item.name)}</div>
      ) : (
        <div className="font-medium">
          {shortFileName(item.name)} ({formatBytes(item.size)})
        </div>
      )}
    </div>
  );
};

export default withRouter(FileName);
