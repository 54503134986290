import React from "react";
import { useMutation } from "react-query";
import { removeFile } from "../api/file.api";
import AppModal from "../components/AppModal";
import Button from "../components/Button";
import {removeFolder} from "../api/folder.api";

const RemoveModal = ({ isOpen, onClose, file, onSuccess }) => {
  const label = file ? (file.isDir ? 'dossier' : 'fichier') : 'fichier';
  const remove = file ? (file.isDir ? removeFolder : removeFile) : removeFile;
  const removeMutation = useMutation(remove, {
    onSuccess,
  });

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Suppression du {label}</h3>
        {isOpen && (
          <div>
            <div className="mt-2">
              Êtes-vous sûr de vouloir supprimer le {label + " "}
              <span className="font-medium">{file.name}</span> ?
            </div>

            <div className="text-right mt-4">
              <Button
                color="red"
                onClick={() => removeMutation.mutate(file._id)}
                isDisabled={removeMutation.isLoading}
              >
                {removeMutation.isLoading
                  ? "Suppression en cours..."
                  : "Supprimer le " + label}
              </Button>
            </div>
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default RemoveModal;
