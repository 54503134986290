import React from "react";

const Avatar = ({ name }) => {
  return (
    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-gray-100 text-semibold cursor-pointer">
      {name[0].toUpperCase()}
    </div>
  );
};

export default Avatar;
