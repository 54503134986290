import React from "react";
import { useParams, withRouter } from 'react-router-dom'
import { FaFolder } from "react-icons/fa";
import { useQuery } from "react-query";
import { getBreadcrumb } from "../api/folder.api";

const Breadcrumb = ({ history }) => {

  const { folderId } = useParams()
  const getBreadcrumQuery = useQuery(
    ["breadcrumb", folderId],
    getBreadcrumb,
    {
      keepPreviousData: true
    }
  );

  if (getBreadcrumQuery.isLoading || !getBreadcrumQuery.data?.data)
    return <div />;

  return (
    <div className="flex items-center w-full">
      <FaFolder className="mr-2 text-2xl text-red-500" style={{ color: "#F7D383" }} />
      <div className="flex items-center justify-center font-semibold">
        <div className="flex items-center justify-center">
          {getBreadcrumQuery.data.data.parents.map((parent) => (
            <div key={parent._id} className="flex items-center justify-center">
              <div className="mr-2 text-red-500 cursor-pointer hover:text-red-600" onClick={() => history.push(`/repertoires/${parent._id}`)}>
                {parent.name}
              </div>
              <div className="mr-2">/</div>
            </div>
          ))}
        </div>
        <span className="text-red-500">
          {getBreadcrumQuery.data.data.current}
        </span>
      </div>
    </div>
  );
};

export default withRouter(Breadcrumb);
