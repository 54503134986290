import React from "react";
import { useQuery, useQueryClient } from "react-query";
import classNames from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';
import DropdownButton from "../components/dropdowns/DropdownButton";
import DropdownItem from "../components/dropdowns/items/DropdownItem";
import AddFilesModal from "../modals/AddFilesModal";
import NewFolderModal from "../modals/NewFolderModal";
import NewNoteModal from "../modals/NewNoteModal";
import Breadcrumb from "../components/Breadcrumb";
import FolderNavigation from "../components/FolderNavigation";
import { useParams, withRouter } from "react-router-dom";
import { listFolder } from "../api/folder.api";
import { formatBytes } from "../lib/utils";
import { FaChevronCircleLeft, FaTimes, FaChevronCircleRight, FaFileAlt } from "react-icons/fa";
import { UserContext } from "../contexts/UserContext";
import AccessRightsModal from "../modals/AccessRightsModal";
import Loader from "../components/Loader";
import FolderTable from "../components/folderTable/FolderTable";
import SendFileByEmailModal from "../modals/SendFileByEmailModal";
import RemoveModal from "../modals/RemoveModal";
import RenameModal from "../modals/RenameModal";
import MoveModal from "../modals/MoveModal";
import FolderDndFilesBlock from "../components/FolderDndFilesBlock";
import {isAdminFn, isAdminOrInternalFn, isAdminOrInternalOrExternalPlusFn} from "../lib/roleUtils";
import {FiRefreshCcw} from "react-icons/all";
import classnames from "classnames";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPreview = ({pageNumber, setPageNumber, pdfFilePreview, setPdfFilePreview}) => {
  const [pageCount, setPageCount] = React.useState(1);

  return (
    <div className="absolute right-0 w-3/5 p-4 mt-2 ml-3 h-screen overflow-auto shadow-lg bg-gray-50 z-10">
      <div className="text-gray-400 cursor-pointer hover:underline" onClick={() => setPdfFilePreview(null)}>
        <FaTimes />
      </div>
      <div className="mt-3">
        <div className="flex justify-center">
          <div className="mr-2 font-bold text-center hover:underline"
               onClick={() => window.open(pdfFilePreview.location, "_blank")}>
            {pdfFilePreview.name} (({formatBytes(pdfFilePreview.size)}))
          </div>
        </div>
        <div className="mt-2">
          <div className="flex justify-between mb-1">
            <FaChevronCircleLeft className={classNames({
              "text-gray-600 cursor-pointer": pageNumber > 1,
              "text-gray-200": pageNumber === 1
            })}
                                 onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}
            />
            <div className="text-center"> Page {pageNumber} / {pageCount}</div>
            <FaChevronCircleRight className={classNames({
              "text-gray-600 cursor-pointer": pageNumber < pageCount,
              "text-gray-200": pageNumber === pageCount
            })}
                                  onClick={() => pageNumber < pageCount ? setPageNumber(pageNumber + 1) : null}
            />
          </div>
          <div>
            <Document
              file={pdfFilePreview.location}
              onLoadError={error => console.error('PDF ERROR: ', error)}
              onLoadSuccess={({ numPages }) => setPageCount(numPages)}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        </div>
      </div>
    </div>
  )
}

const FilesActions = ({canWrite, setNewFolderModal, setNewNoteModal, setAddFilesModal, setIsAccessRightsOpen,
                       setSelectedFile, folderId, isAdminOrInternal, isAdminOrInternalOrExternalPlus, isAdmin}) => {
  return (
    canWrite ? (
      <DropdownButton
        title="Action"
        dropdownContent={
          <div>
            {isAdminOrInternalOrExternalPlus && (
              <DropdownItem
                title="Créer un dossier"
                onClick={() => setNewFolderModal(true)}
              />
            )}
            {isAdminOrInternal && (
              <DropdownItem
                title="Créer une note"
                onClick={() => setNewNoteModal(true)}
              />
            )}
            <DropdownItem
              title="Téléverser des fichiers"
              onClick={() => setAddFilesModal(true)}
            />
            {
              isAdmin && (
                <DropdownItem
                  title="Droits d'accès"
                  onClick={() => {
                    setIsAccessRightsOpen(true);
                    setSelectedFile({_id: folderId})
                  }}
                />
              )
            }
          </div>
        }
      />
    ) : (
      <></>
    )
  )
}

const FilesTable = ({history, setNewFolderModal, setNewNoteModal, setAddFilesModal, setSendByEmailModal, setRenameModal, setMoveModal, setRemoveModal,
                      setIsAccessRightsOpen, setSelectedFile, pdfFilePreview, setPdfFilePreview, pageNumber, setPageNumber}) => {
  const queryClient = useQueryClient();
  const { folderId } = useParams();
  const listFolderQuery = useQuery(["listFolder", folderId], listFolder);

  const { authData } = React.useContext(UserContext);
  const isAdmin = isAdminFn(authData.user.role);
  const isAdminOrInternal = isAdminOrInternalFn(authData.user.role);
  const isAdminOrInternalOrExternalPlus = isAdminOrInternalOrExternalPlusFn(authData.user.role);

  const [refreshing, setRefreshing] = React.useState(false);
  const refresh = () => {
    setRefreshing(true);
    listFolderQuery.refetch().then(() => setRefreshing(false));
  }

  return (
      listFolderQuery.isLoading ? (
        <div className="flex flex-col flex-grow">
          <div className="flex flex-col flex-grow mt-2 overflow-scroll bg-gray-100">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="flex flex-col flex-grow">
          <div className="flex flex-row items-center justify-between">
            <Breadcrumb />
            <div className="flex items-center justify-center">
              {(isAdminOrInternal && history.location.pathname !== '/repertoires') && (
                <div className='flex flex-row items-center justify-end'>
                  <button
                    className='mr-4 flex flex-row items-center justify-between px-4 py-3 text-sm font-bold text-white
                               bg-yellow-400 outline-none focus:outline-none'
                    onClick={() => {
                      setNewNoteModal(true);
                      setSelectedFile();
                    }}
                  >
                    <FaFileAlt className='mr-2' /> Note
                  </button>
                </div>
              )}
              <div className='flex flex-row items-center justify-end'>
                <button
                  className='mr-4 flex flex-row items-center justify-between px-4 py-3 text-sm font-bold text-white
                               bg-blue-400 outline-none focus:outline-none'
                  onClick={() => {
                    refresh();
                  }}
                >
                  <FiRefreshCcw className={classnames('mr-2', {'animate-spin': refreshing})} /> Actualiser
                </button>
              </div>
              <FilesActions canWrite={listFolderQuery.data.data.canWrite}
                            setNewFolderModal={setNewFolderModal}
                            setNewNoteModal={setNewNoteModal}
                            setAddFilesModal={setAddFilesModal}
                            setIsAccessRightsOpen={setIsAccessRightsOpen}
                            setSelectedFile={setSelectedFile}
                            folderId={folderId}
                            isAdminOrInternal={isAdminOrInternal}
                            isAdminOrInternalOrExternalPlus={isAdminOrInternalOrExternalPlus}
                            isAdmin={isAdmin}
              />
            </div>
          </div>
          <div className="relative flex flex-row">
            <FolderTable data={listFolderQuery.data.data}
                         onPreviewPdf={setPdfFilePreview}
                         onSendByEmail={(file) => {
                           setSendByEmailModal(true);
                           setSelectedFile(file);
                         }}
                         onRename={(file) => {
                           setRenameModal(true);
                           setSelectedFile(file);
                         }}
                         onMove={(file) => {
                           setMoveModal(true);
                           setSelectedFile(file);
                         }}
                         onNote={(file) => {
                           setNewNoteModal(true);
                           setSelectedFile(file);
                         }}
                         onAccessRights={(file) => {
                           setIsAccessRightsOpen(true);
                           setSelectedFile(file);
                         }}
                         onRemove={(file) => {
                           setRemoveModal(true);
                           setSelectedFile(file);
                         }}
            />

            {
              pdfFilePreview && <PdfPreview pageNumber={pageNumber}
                                            setPageNumber={setPageNumber}
                                            pdfFilePreview={pdfFilePreview}
                                            setPdfFilePreview={setPdfFilePreview} />
            }
          </div>
          <div className="relative flex flex-row flex-grow bg-gray-100">
            {
              !!folderId && listFolderQuery.data.data && listFolderQuery.data.data.canWrite &&
              <FolderDndFilesBlock onSuccess={() => {
                queryClient.invalidateQueries(["listFolder", folderId])
              }} />
            }
          </div>
        </div>
      )
  )
}

const Files = ({ history }) => {
  const queryClient = useQueryClient();
  const { folderId } = useParams();

  const { authData } = React.useContext(UserContext);
  const [selectedFile, setSelectedFile] = React.useState(undefined);
  const [pdfFilePreview, setPdfFilePreview] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [newFolderModal, setNewFolderModal] = React.useState(false);
  const [newNoteModal, setNewNoteModal] = React.useState(false)
  const [addFilesModal, setAddFilesModal] = React.useState(false);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [renameModal, setRenameModal] = React.useState(false);
  const [moveModal, setMoveModal] = React.useState(false);
  const [sendByEmailModal, setSendByEmailModal] = React.useState(false);
  const [isAccessRightsOpen, setIsAccessRightsOpen] = React.useState(false);

  React.useEffect(() => {
    setPageNumber(1)
  }, [pdfFilePreview])

  const isAdmin = isAdminFn(authData.user.role);

  React.useEffect(() => {
    setPdfFilePreview(null)
  }, [history.location.pathname])

  return (
    <div className="flex flex-grow">
      <FolderNavigation />

      <FilesTable
        history={history}
        setNewNoteModal={setNewNoteModal}
        setNewFolderModal={setNewFolderModal}
        setSendByEmailModal={sendByEmailModal}
        setAddFilesModal={setAddFilesModal}
        setRenameModal={setRenameModal}
        setMoveModal={setMoveModal}
        setRemoveModal={setRemoveModal}
        setIsAccessRightsOpen={setIsAccessRightsOpen}
        setSelectedFile={setSelectedFile}
        pdfFilePreview={pdfFilePreview}
        setPdfFilePreview={setPdfFilePreview}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />

      {isAccessRightsOpen && isAdmin && (
        <AccessRightsModal isOpen={isAccessRightsOpen}
                           onSuccess={() => {
                             setIsAccessRightsOpen(false);
                             setSelectedFile(null);
                           }}
                           onClose={() => {
                             setIsAccessRightsOpen(false);
                             setSelectedFile(null)
                           }}
                           file={selectedFile}
        />
      )}

      <NewFolderModal
        isOpen={newFolderModal}
        onClose={() => setNewFolderModal(false)}
        onSuccess={() => {
          queryClient.invalidateQueries(["listFolder", folderId]);
          queryClient.invalidateQueries("folders");
          setNewFolderModal(false);
        }}
      />

      <NewNoteModal
        isOpen={newNoteModal}
        onSuccess={() => {
          setNewNoteModal(false);
          setSelectedFile(null);
          queryClient.invalidateQueries(["listFolder", folderId]);
        }}
        onClose={() => {
          setNewNoteModal(false)
          setSelectedFile(null);
        }}
        file={selectedFile}
      />

      <AddFilesModal
        isOpen={addFilesModal}
        onClose={() => setAddFilesModal(false)}
        onSuccess={() => {
          queryClient.invalidateQueries(["listFolder", folderId]);
          setAddFilesModal(false);
        }}
      />

      <RemoveModal
        isOpen={removeModal}
        onSuccess={() => {
          setRemoveModal(false);
          setSelectedFile(null);
          queryClient.invalidateQueries("listFolder");
          queryClient.invalidateQueries("folders");
        }}
        onClose={() => {
          setRemoveModal(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
      />

      <RenameModal
        isOpen={renameModal}
        onSuccess={() => {
          setRenameModal(false);
          setSelectedFile(null);
          queryClient.invalidateQueries("listFolder");
          queryClient.invalidateQueries("folders");
        }}
        onClose={() => {
          setRenameModal(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
      />

      <MoveModal
        isOpen={moveModal}
        onSuccess={() => {
          setMoveModal(false);
          setSelectedFile(null);
          queryClient.invalidateQueries("listFolder");
          queryClient.invalidateQueries("folders");
        }}
        onClose={() => {
          setMoveModal(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
      />

      <SendFileByEmailModal
        isOpen={sendByEmailModal}
        onSuccess={() => {
          setSendByEmailModal(false);
          setSelectedFile(null);
        }}
        onClose={() => {
          setSendByEmailModal(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
      />
    </div>
  );
};

export default withRouter(Files);
