import React from "react";
import Popup from "reactjs-popup";
import { FaChevronDown } from "react-icons/fa";
import Button from "../Button";

const DropdownButton = ({ title, dropdownContent, outline, size = 'md' }) => {
  return (
    <Popup
      trigger={
        <div>
          <Button outline={outline} size={size}>
            <div className="mr-2">{title}</div>
            <FaChevronDown style={{ position: "relative", top: "1px" }} />
          </Button>
        </div>
      }
      position="bottom right"
      on="hover"
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: "0px", border: "none" }}
      arrow={false}
    >
      <div className="p-3">{dropdownContent}</div>
    </Popup>
  );
};

export default DropdownButton;
