import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { sendFileByEmail } from "../api/file.api";

import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Input from "../components/Input";

const SendFileByEmailModal = ({ isOpen, onClose, onSuccess, file }) => {
  const { register, handleSubmit, errors } = useForm();
  const sendFileByEmailMutation = useMutation(sendFileByEmail, {
    onSuccess,
  });

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      {
        !!file && (
          <div>
            <h3 className="text-xl font-semibold">Envoyer le fichier <span className="whitespace-nowrap">« {file.name} »</span> par email</h3>
            {isOpen && (
              <form
                onSubmit={handleSubmit((values) =>
                  sendFileByEmailMutation.mutate({
                    ...values,
                    fileId: file._id,
                  })
                )}
              >
                <div className="mt-2">
                  <Input
                    label="Email du destinataire"
                    inputRef={register({
                      required: "Ce champ est obligatoire",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "L'adresse email est invalide",
                      },
                    })}
                    error={errors.recipient}
                    name={"recipient"}
                  />
                </div>

                {!!sendFileByEmailMutation.error && (
                  <div className="text-red-500">
                    {sendFileByEmailMutation.error.message}
                  </div>
                )}

                <div className="mt-4 text-right">
                  <Button type="submit" isDisabled={sendFileByEmailMutation.isLoading}>
                    {sendFileByEmailMutation.isLoading
                      ? "Envoi du fichier..."
                      : "Envoyer le fichier"}
                  </Button>
                </div>
              </form>
            )}
          </div>

        )
      }
    </AppModal>
  );
};

export default SendFileByEmailModal;
