import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalOrExternalPlusFn} from "../../../lib/roleUtils";

export const DropdownItemRename = ({item, role, canWrite, onRename}) => {
  return isAdminOrInternalOrExternalPlusFn(role) && canWrite ? <DropdownItem
    title="Renommer"
    onClick={() => {
      onRename(item);
    }}
  /> : <></>;
}