import React from "react";
import { UserContext } from "../contexts/UserContext";
import MenuItem from "./MenuItem";

const Menu = () => {
  const { authData } = React.useContext(UserContext);

  // if (authData.user.role === "external_user") return null;
  return (
    <div className="grid grid-cols-4 gap-4 mb-5">
      {["admin", "internal_user"].indexOf(authData.user.role) !== -1 ? (
        <>
          <MenuItem path={"/tableau-de-bord"}>Tableau de bord</MenuItem>
          <MenuItem path={"/repertoires"}>Répertoires</MenuItem>
          <MenuItem path={"/utilisateurs"}>Utilisateurs</MenuItem>
          <MenuItem path={"/configuration"}>Paramètres</MenuItem>
        </>
      ) : (
          <>
            <MenuItem path={"/repertoires"}>Répertoires</MenuItem>
          </>
        )}
    </div>
  );
};

export default Menu;
