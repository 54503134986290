import React from "react";
import moment from "moment";
import classnames from 'classnames'
import { FaUserEdit } from "react-icons/fa";
import { UserContext } from "../contexts/UserContext";

const RoleLabel = ({ role }) => {
  if (role === "admin") {
    return (
      <div className="inline px-2 text-sm bg-red-300 rounded-md">
        Administrateur
      </div>
    );
  }
  if (role === "internal_user") {
    return (
      <div className="inline px-2 text-sm bg-yellow-300 rounded-md">
        Utilisateur interne
      </div>
    );
  }
  if (role === "external_user+") {
    return (
      <div className="inline px-2 text-sm bg-blue-300 rounded-md">
        Utilisateur externe +
      </div>
    );
  }
  if (role === "external_user") {
    return (
      <div className="inline px-2 text-sm bg-green-300 rounded-md">
        Utilisateur externe
      </div>
    );
  }
  return <div className="px-2 text-sm bg-gray-300 rounded-md">{role}</div>;
};

const UserLine = ({ user, onSelect }) => {
  const { authData } = React.useContext(UserContext);

  const isAdmin = authData.user.role === 'admin';
  return (
    <div className="flex justify-between flex-grow px-2 py-3 border-b-2">
      {
        isAdmin && (
          <div className="px-2">
            <div>
              <FaUserEdit onClick={() => onSelect(user)} style={{ position: 'relative', top: "2px" }} className="cursor-pointer" />
            </div>
          </div>
        )
      }
      <div className={classnames("font-medium cursor-pointer w-60", { "hover:underline": isAdmin })} onClick={() => isAdmin ? onSelect(user) : null}>{user.name}</div>
      <div className="flex-grow text-sm">{user.email}</div>
      <div className="w-40 text-center">
        <RoleLabel role={user.role} />
      </div>
      <div className="text-sm text-right text-gray-500 w-60">
        ajouté le {moment(user.createdAt).format("DD/MM/YYYY à HH:mm")}
      </div>
    </div>
  );
};

export default UserLine;
