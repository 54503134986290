import React from "react";
import moment from "moment";
import {DropdownButtonActions} from "../dropdowns/DropdownButtonActions";
import {UserContext} from "../../contexts/UserContext";
import FileName from "../FileName";
import {FolderNote} from "./FolderNote";

export const FolderColumns = ({canWrite, onPreviewPdf, onSendByEmail, onRename, onMove, onNote,
                                onAccessRights, onRemove}) => {
  const { authData } = React.useContext(UserContext);

  return React.useMemo(() => [
    {
      Header: 'Nom',
      accessor: 'name',
      align: 'left',
      Cell: function display({row}) {
        const item = row.original;
        return <FileName item={item} onPreviewPdf={onPreviewPdf}/>;
      }
    },
    {
      Header: 'Nb fichiers',
      accessor: 'nbFiles',
      align: 'left',
      width: 90,
      disableResizing: true,
      disableSortBy: true
    },
    {
      accessor: 'note',
      align: 'right',
      width: 35,
      disableResizing: true,
      disableSortBy: true,
      Cell: ({value, row}) => {
        const file = row.original;
        if (value) {
          return <FolderNote file={file} onNote={onNote} />;
        } else {
          return '';
        }
      }
    },
    {
      Header: 'Modifié le',
      accessor: 'updatedAt',
      width: 150,
      disableResizing: true,
      Cell: ({value}) => moment(value).format("DD/MM/YYYY HH:mm")
    },
    {
      accessor: '_id',
      align: 'right',
      width: 120,
      disableResizing: true,
      disableSortBy: true,
      Cell: function display({row}) {
        const file = row.original;
        return <DropdownButtonActions item={file}
                                      canWrite={canWrite}
                                      role={authData.user.role}
                                      onSendByEmail={onSendByEmail}
                                      onRename={onRename}
                                      onMove={onMove}
                                      onNote={onNote}
                                      onAccessRights={onAccessRights}
                                      onRemove={onRemove}
        />;
      }
    }
  ], []);
}
