import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalFn} from "../../../lib/roleUtils";

export const DropdownItemSendByEmail = ({item, role, onSendByEmail}) => {
  const sendFileByEmail = (item) => {
    if (item.size > 5242880) return alert("Impossible d'envoyer par email les fichiers de plus de 5Mo")
    onSendByEmail(item)
  }

  return !item.isDir && isAdminOrInternalFn(role) ? (
      <DropdownItem
        title="Envoyer par email"
        onClick={() => {
          sendFileByEmail(item)
        }}
      />
    ) : <></>;
}