import DropdownItem from "./DropdownItem";
import React from "react";
import {isAdminOrInternalFn} from "../../../lib/roleUtils";

export const DropdownItemAccessRights = ({item, role, onAccessRights}) => {
  return item.isDir && isAdminOrInternalFn(role) ? (
    <DropdownItem
      title="Droits d'accès"
      onClick={() => {
        onAccessRights(item);
      }}
    />
  ) : <></>;
}