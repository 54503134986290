import React from "react";
import { FaCaretSquareDown, FaCaretSquareRight } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { moveFile } from "../api/file.api";
import {getFolders, moveFolder} from "../api/folder.api";
import AppModal from "../components/AppModal";
import Loader from "../components/Loader";

const MoveModal = ({ isOpen, onClose, file, onSuccess }) => {
  console.log("paint moveModal");
  const getFoldersQuery = useQuery("folders", getFolders);
  const label = file ? (file.isDir ? 'dossier' : 'fichier') : 'fichier';
  const move = file ? (file.isDir ? moveFolder : moveFile) : moveFile;
  const moveMutation = useMutation(move, {
    onSuccess,
  });

  if (moveMutation.isLoading) {
    return <AppModal isOpen={isOpen} onClose={onClose}><Loader /></AppModal>
  }

  const FolderSelector = ({ folder }) => {
    const [isOpen, setOpen] = React.useState(false)
    const [hover, setHover] = React.useState(false)

    return (
      <div>
        <div key={folder._id} className="flex items-center p-1" onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          {
            folder.children.length ? (
              <div className="w-5 text-sm text-blue-400 cursor-pointer" onClick={() => setOpen(!isOpen)}>{isOpen ? <FaCaretSquareDown /> : <FaCaretSquareRight />}</div>
            ) : (
              <div className="w-5" />
            )
          }
          <span className="mr-2">
            {folder.name}
          </span>
          {
            hover && (
              <span className="mr-2 text-sm text-blue-400 cursor-pointer hover:underline" onClick={() => {
                if (file.isDir) {
                  moveMutation.mutate({ newParentFolderId: folder._id, folderId: file._id })
                } else {
                  moveMutation.mutate({folderId: folder._id, fileId: file._id})
                }
              }}>
                Déplacer dans ce dossier
              </span>
            )
          }
        </div>
        {
          isOpen && (
            <div className="ml-6">
              {
                folder.children.map(folder => <FolderSelector key={folder._id} folder={folder} />)
              }
            </div>
          )
        }
      </div >
    )
  }

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Déplacement du {label}</h3>
        {isOpen && (
          <div>
            <div className="mt-2">
              Dans quel dossier voulez-vous déplacer le {label + " "}
              <span className="font-medium">{file.name}</span> ?
            </div>

            <div className="mt-6">
              {getFoldersQuery.data.data.map((folder) => (
                <FolderSelector key={folder._id} folder={folder} />
              ))}
            </div>

            {/* <div className="mt-4 text-right">
              <Button
                onClick={() => moveFileMutation.mutate(file._id)}
                isDisabled={moveFileMutation.isLoading}
              >
                {moveFileMutation.isLoading
                  ? "Déplacement en cours..."
                  : "Déplacer le fichier"}
              </Button>
            </div> */}
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default MoveModal;
