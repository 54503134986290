import { useContext } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import NonAuthLayout from "../layouts/NonAuthLayout";

const NonAuthMiddleware = ({ component: Component, path }) => {
  const { authData } = useContext(UserContext);
  return (
    <Route
      exact
      path={path}
      render={(props) => {
        if (authData.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname:
                  authData.user.role === "external_user" || authData.user.role === "external_user+"
                    ? "/repertoires"
                    : "/tableau-de-bord"
              }}
            />
          );
        }

        return (
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
        );
      }}
    />
  );
};

export default withRouter(NonAuthMiddleware);
