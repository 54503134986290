import React, { createContext } from "react";

export const FileSelectorContext = createContext({
  selectedFiles: [],
  addFileToSelection: () => { },
  resetSelection: () => { },
  downloadSelectedFiles: () => { },
});

// eslint-disable-next-line
export default ({ children }) => {
  const [selectedFiles, setSelectedFiles] = React.useState([])

  return (
    <FileSelectorContext.Provider
      value={{
        selectedFiles,
        addFileToSelection: (file) => {
          setSelectedFiles([...selectedFiles, file])
        },
        removeFileToSelection: (file) => {
          const selectedFileFiltered = selectedFiles.filter(selectedFile => selectedFile._id !== file._id)
          setSelectedFiles(selectedFileFiltered)
        },
        resetSelection: () => {
          setSelectedFiles([])
        },
        downloadSelectedFiles: () => {
          selectedFiles.forEach(selectedFile => window.open(selectedFile.location, '_blank'))
          setSelectedFiles([])
        }
      }}
    >
      {children}
    </FileSelectorContext.Provider>
  );
};
