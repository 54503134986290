import axios from "axios";

export const zipFiles = (data) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/filesystem/zipFiles`,
    data: data,
    responseType: 'blob',
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'archives.zip'); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};
