import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import AppModal from "../components/AppModal";
import Input from "../components/Input";
import Button from "../components/Button";
import { addNotificationEmailAddress } from "../api/settings.api";

const AddEmailNotificationModal = ({ isOpen, onClose, onSuccess }) => {
  const { register, handleSubmit, errors } = useForm();

  const addNotificationEmailAddressMutation = useMutation(
    addNotificationEmailAddress,
    {
      onSuccess,
    }
  );

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">
          Ajouter un email de notification
        </h3>

        {isOpen && (
          <div className="mt-3">
            <form
              onSubmit={handleSubmit(
                addNotificationEmailAddressMutation.mutate
              )}
            >
              <div className="mt-2">
                <Input
                  label="Email"
                  inputRef={register({
                    required: "Ce champ est obligatoire",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "L'adresse email est invalide",
                    },
                  })}
                  error={errors.email}
                  name={"email"}
                />
              </div>

              {!!addNotificationEmailAddressMutation.error && (
                <div className="text-red-500">
                  {addNotificationEmailAddressMutation.error.message}
                </div>
              )}

              <div className="text-right mt-4">
                <Button
                  type="submit"
                  isDisabled={addNotificationEmailAddressMutation.isLoading}
                >
                  {addNotificationEmailAddressMutation.isLoading
                    ? "Ajout de l'email..."
                    : "Valider et ajouter"}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default AddEmailNotificationModal;
