import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { addFileInFolder } from '../api/file.api';
import asyncPool from "tiny-async-pool";
import Loader from './Loader';

const FolderDndFilesBlock = ({ onSuccess }) => {
  const { folderId } = useParams()
  const [loading, setLoading] = React.useState(false);

  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      async function uploadPromise(file) {
        return new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append("file", file);
          try {
            addFileInFolder(folderId, formData).then(resolve)
            // resolve()
          } catch (e) {
            reject(e)
          }
        })
      }
      return asyncPool(1, acceptedFiles, uploadPromise).then(() => {
        setLoading(false)
        onSuccess();
      });
    },
    [onSuccess, folderId]
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ onDrop, maxSize: 1048576 * 127 }); // 127Mo limit

  if (loading) {
    return (
      <div className="flex items-center justify-center flex-grow" style={{ minHeight: '60px' }}>
        <Loader title="Téléversement de vos fichiers..." />
      </div>
    )
  }

  return (
    <>
      {
        fileRejections && fileRejections[0] && fileRejections[0].errors[0]?.code === 'file-too-large' && (
          <div className="mt-3 text-center text-red-400">
            La taille limite des fichiers est de 50 Mo
          </div>
        )
      }

      <div className="flex items-center justify-center flex-grow" {...getRootProps()} style={{ minHeight: '60px' }}>
        <input {...getInputProps()} />

        {isDragActive ? (
          <p className="text-gray-400">Déposez vos fichiers ici ...</p>
        ) : (
          <p className="text-gray-400">Cliquez ici pour importer des nouveaux fichiers</p>
        )}
      </div>
    </>
  );
};

export default FolderDndFilesBlock;
