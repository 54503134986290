import React from "react";

const DropdownItem = ({ onClick, title }) => {
  return (
    <div
      className="p-2 hover:bg-gray-100	cursor-pointer whitespace-nowrap"
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default DropdownItem;
