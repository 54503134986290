import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import AuthLayout from "../layouts/AuthLayout";

const CheckRoleMiddleware = ({ component: Component, roles, path }) => {
  const { authData } = React.useContext(UserContext);
  return (
    <Route
      path={path}
      render={function (props) {
        if (!authData.isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: "/connexion", state: { from: props.location } }}
            />
          );
        }

        const hasRole = roles.indexOf(authData.user.role) !== -1;

        if (!hasRole) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
        }

        return (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};

export default withRouter(CheckRoleMiddleware);
