import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./tailwind.generated.css";

import UserContextProvider from "./contexts/UserContext";
import FileSelectorProvider from "./contexts/FileSelectorContext";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <FileSelectorProvider>
        <App />
      </FileSelectorProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
