import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createRootFolder } from "../api/folder.api";

import AppModal from "../components/AppModal";
import Button from "../components/Button";
import Input from "../components/Input";

const CreateRootFolderModal = ({ isOpen, onClose, onSuccess }) => {
  const { register, handleSubmit, errors } = useForm();

  const createRootFolderMutation = useMutation(createRootFolder, {
    onSuccess,
  });

  let inputProps = {
    autoFocus: true
  };

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className="text-xl font-semibold">Créer un client</h3>
        {isOpen && (
          <form onSubmit={handleSubmit(createRootFolderMutation.mutate)}>
            <div className="mt-2">
              <Input
                label="Nom du dossier"
                inputRef={register({
                  required: "Ce champ est obligatoire",
                })}
                error={errors.folderName}
                name="folderName"
                inputProps={inputProps}
              />
            </div>

            {!!createRootFolderMutation.error && (
              <div className="text-red-500">
                {createRootFolderMutation.error.message}
              </div>
            )}

            <div className="mt-4 text-right">
              <Button type="submit" isDisabled={createRootFolderMutation.isLoading}>
                {createRootFolderMutation.isLoading
                  ? "Création du client..."
                  : "Valider et créer"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default CreateRootFolderModal;
