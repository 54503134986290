import axios from "axios";

export const listUsers = (ctx) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/users?page=${ctx.queryKey[1]}&limit=10`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

// export const getUsersForAccessRights = (ctx) => {
//   return axios({
//     method: "GET",
//     url: `${process.env.REACT_APP_API_URL}/v1/users?page=${ctx.queryKey[1]}&limit=10`,
//     headers: {
//       authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
//     },
//   });
// };

export const createUser = (values) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/users`,
    data: { ...values, clientFolderId: values.clientFolderId.filter(clientFolder => !!clientFolder) },
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const changePassword = (values) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/users/${values.userId}/change-password`,
    data: { password: values.password },
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const removeUser = (userId) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/v1/users/${userId}`,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
};

export const updateUser = (values) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/v1/users/${values._id}`,
    data: {
      ...values,
      _id: undefined,
      createdAt: undefined
    },
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  });
}
