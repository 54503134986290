import DropdownButton from "./DropdownButton";
import React from "react";
import DropdownItemOpen from "./items/DropdownItemOpen";
import {DropdownItemSendByEmail} from "./items/DropdownItemSendByEmail";
import {DropdownItemRename} from "./items/DropdownItemRename";
import {DropdownItemMove} from "./items/DropdownItemMove";
import {DropdownItemCreateNote} from "./items/DropdownItemCreateNote";
import {DropdownItemAccessRights} from "./items/DropdownItemAccessRights";
import {DropdownItemDelete} from "./items/DropdownItemDelete";

export const DropdownButtonActions = ({item, role, canWrite, onSendByEmail, onRename, onMove, onNote,
                                        onAccessRights, onRemove}) => {
  return <DropdownButton
    outline
    size='sm'
    title="Actions"
    dropdownContent={
      <div>
        <DropdownItemOpen item={item} />
        <DropdownItemOpen item={item} newTab={true} />
        <DropdownItemSendByEmail item={item} role={role} onSendByEmail={onSendByEmail} />
        <DropdownItemRename item={item} role={role} canWrite={canWrite} onRename={onRename} />
        <DropdownItemMove item={item} role={role} canWrite={canWrite} onMove={onMove} />
        <DropdownItemCreateNote item={item} role={role} onNote={onNote} />
        <DropdownItemAccessRights item={item} role={role} onAccessRights={onAccessRights} />
        <DropdownItemDelete item={item} role={role} canWrite={canWrite} onRemove={onRemove} />
      </div>
    }
  />;
}