
export const isAdminFn = (role) => {
  return "admin" === role;
}

export const isAdminOrInternalFn = (role) => {
  return ["admin", "internal_user"].indexOf(role) !== -1;
}

export const isAdminOrInternalOrExternalPlusFn = (role) => {
  return ["admin", "internal_user", "external_user+"].indexOf(role) !== -1;
}