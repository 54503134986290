import React from "react";
import Menu from "./Menu";
import NavBar from "./NavBar";

const AuthLayout = ({ children }) => {
  return (
    <div id="AuthLayout" className="p-3 flex flex-col h-screen">
      <NavBar />

      <div className="mt-1">
        <Menu />
      </div>

      <div className="content mt-2 flex-grow flex">{children}</div>
    </div>
  );
};

export default AuthLayout;
