import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { changePassword, removeUser, updateUser } from '../api/user.api';
import AppModal from '../components/AppModal';
import Button from '../components/Button';

const UserDetailsModal = ({ isOpen, onClose, user }) => {
  const roleRegister = useForm().register;
  const { register, handleSubmit, errors } = useForm();
  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      onClose()
    }
  })

  const changePasswordMutation = useMutation(changePassword, {
    onSuccess: onClose
  });

  const removeUserMutation = useMutation(removeUser, {
    onSuccess: onClose
  });

  const [role, setRole] = React.useState(user.role)

  return (
    <AppModal isOpen={isOpen} onClose={onClose}>
      <div>

        <h3 className="text-xl font-semibold">Modification de l'utilisateur {user.name}</h3>

        <div className="mt-4">
          <h3 className="font-semibold">Changer le rôle de l'utilisateur</h3>

          <div className="flex flex-col">
            <div>
              <label>
                <input
                  onChange={e => setRole(e.currentTarget.value)}
                  name="role"
                  checked={role === 'admin'}
                  type="radio"
                  value="admin"
                  ref={roleRegister({ required: "Ce champ est requis" })}
                />
                <span> Administrateur</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  onChange={e => setRole(e.currentTarget.value)}
                  name="role"
                  type="radio"
                  checked={role === 'internal_user'}
                  value="internal_user"
                  ref={roleRegister({ required: "Ce champ est requis" })}
                />
                <span> Utilisateur interne</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  onChange={e => setRole(e.currentTarget.value)}
                  name="role"
                  type="radio"
                  checked={role === 'external_user+'}
                  value="external_user+"
                  ref={roleRegister({ required: "Ce champ est requis" })}
                />
                <span> Utilisateur externe +</span>
              </label>
            </div>

            <div>
              <label>
                <input
                  onChange={e => setRole(e.currentTarget.value)}
                  name="role"
                  type="radio"
                  checked={role === 'external_user'}
                  value="external_user"
                  ref={roleRegister({ required: "Ce champ est requis" })}
                />
                <span> Utilisateur externe</span>
              </label>
            </div>

          </div>
        </div>

        <div className="flex justify-between mt-4">
          <Button color="red"
            isDisabled={removeUserMutation.isLoading}
            onClick={() => removeUserMutation.mutate(user._id)}>{removeUserMutation.isLoading
              ? "Suppression de l'utilisateur..."
              : "Supprimer l'utilisateur"}</Button>
          <Button isDisabled={user.role === role} onClick={() => { updateUserMutation.mutate({ ...user, role }) }}>Sauvegarder</Button>
        </div>


        <hr className="mt-6 mb-2" />

        {isOpen && (
          <form
            className="mt-3"
            onSubmit={handleSubmit((values) => changePasswordMutation.mutate({ ...values, userId: user._id }))}
          >
            <h3 className="font-semibold">Changer le mot de passe de l'utilisateur</h3>
            <div className="flex mt-2">

              <input placeholder="Nouveau mot de passe"
                ref={register({
                  required: "Ce champ est obligatoire",
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i,
                    message:
                      "Le mot de passe doit faire au moins 8 caractères et doit contenir au moins 1 lettre minuscule, 1 lettre majuscule et 1 chiffre",
                  },
                })}
                className="flex-grow p-2 border-2 border-gray-500"
                type="password"
                name={"password"}
              />
              <Button type="submit" isDisabled={changePasswordMutation.isLoading} >
                {changePasswordMutation.isLoading
                  ? "Modification..."
                  : "Modifier"}
              </Button>
            </div>

            <hr />

            {
              !!errors.password && (
                <div className="text-red-500">{errors.password.message}</div>
              )
            }

            {!!changePasswordMutation.error && (
              <div className="text-red-500">
                {changePasswordMutation.error.message}
              </div>
            )}
          </form>
        )}
      </div>
    </AppModal>
  );
};

export default UserDetailsModal;
