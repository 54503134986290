import DropdownItem from "./DropdownItem";
import React from "react";
import {withRouter} from "react-router-dom";

const DropdownItemOpen = ({item, newTab = false, history}) => {
  const title = newTab ? 'Ouvrir dans un onglet' : 'Ouvrir';

  return <DropdownItem
    title={title}
    onClick={() => {
      if (item.isDir) {
        if (newTab) {
          window.open(`#/repertoires/${item._id}`);
        } else {
          history.push(`/repertoires/${item._id}`);
        }
      } else {
        window.open(item.location, "_blank");
      }
    }}
  />;
}

export default withRouter(DropdownItemOpen);